/* ==========================================================================
   Variables
   ========================================================================== */

/* Colors
   ========================================================================== */

$base-color: #14213d; // Big Stone
$primary-color: #02a0db; //
$darker-primary-color: #0070be;
$secondary-color: #ffc700; // Supernova
$background-color: white;
$home-light-color: #bbe4f3;
$home-darker-color: #9cd5ec;

/* State */

$danger-color: #d85d3f; // Outrageous Orange
$success-color: #216869; // Myrtle Green
$warning-color: $secondary-color;
$info-color: $primary-color;

/* Helpers */

$link-color: $primary-color;
$base-alpha-color: rgba($base-color, 0.08);

/* Infographics */

$alpha-color: #fe5931;
$beta-color: #ffc700;
$gama-color: #1ea896;
$delta-color: #19647e;

/* Typography
   ========================================================================== */

$root-font-size: 16px;

$base-font-color: tint($base-color, 16%);
$base-font-family: "Rubik", sans-serif;
$base-font-style: normal;
$base-font-light: 300;
$base-font-regular: 400;
$base-font-medium: 500;
$base-font-bold: 500; // Medium (500) being used as bold (700)
$base-font-weight: $base-font-light;
$base-font-size: 1rem;
$base-line-height: 1.5;

$code-font-family: Menlo, Monaco, Consolas, "Courier New", monospace;

$heading-font-family: $base-font-family;
$heading-font-light: 300;
$heading-font-regular: 400;
$heading-font-medium: 500;
$heading-font-bold: 500; // Medium (500) being used as bold (700)
$heading-font-weight: $heading-font-medium;

/* Decoration
   ========================================================================== */

/* Border radius */

$base-border-radius: 0.25rem;
$full-border-radius: 320rem;

$base-border-width: 1px;

/* Sizing, spacing and media queries
   ========================================================================== */

/* Spacing */

$global-spacing: 1rem;
$contained-spacing: 1.5rem;

/* Rows */

$row-min-width: 960px;
$row-max-width: 1280px;
$jeet-max-width: $row-max-width; // reset jeet.gs max width

/* Media queries */

$xsmall-range: (0, 543px);
$small-range: (544px, 767px);
$medium-range: (768px, 991px);
$large-range: (992px, 1199px);
$xlarge-range: (1200px);

$screen: "only screen";

/* slider marker */

$input-range-slider-height: 0.85rem;
$input-range-slider-width: 0.85rem;

/* input range track */
$input-range-track-height: 0.25rem;

/* ==========================================================================
   Navs
   ========================================================================== */

.nav {
  color: $base-font-color;
  margin-bottom: $global-spacing;
}

.nav__link {
  @extend .heading-alt;
  font-size: 0.875rem;
  line-height: 1rem;
  display: inline-flex;
  padding: 0.75rem 0;
  user-select: none;
}

.nav__link {
  position: relative;
  font-weight: $base-font-bold;

  /* Animation */
  transition: color 0.16s ease-in-out 0s;

  &,
  &:visited {
    color: inherit;
  }

  &:hover {
    opacity: 1;
    color: $link-color;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: "";
    width: 0;
    height: 2px;
    background: $link-color;
    transform: translate(-50%, 0);

    /* Animation */
    transition: width 0.24s ease-in-out 0s;
  }

  &--active {
    color: $link-color;

    &::after {
      width: 100%;
    }
  }
}

/* Tablist
   ========================================================================== */

.nav__tablist {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: inset 0 (-$base-border-width) 0 0 $base-alpha-color;
}

.nav__tab {
  margin: 0 ($global-spacing * 1.5) 0 0;
  padding: 0;
}

/* ==========================================================================
   Home: global
   ========================================================================== */

.inpage--home {
  @extend .antialiased;
  position: relative;
  background: shade($home-light-color, 36%);
  color: $darker-primary-color;

  @include media(large-up) {
    background: linear-gradient(
      to bottom,
      $home-light-color 0%,
      $home-light-color 60.73%,
      $home-darker-color 60.73%
    );
  }

  .inpage__header {
    height: 0;
  }

  .inpage__body {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    min-height: calc(100vh - 24px);

    @include media(large-up) {
      min-height: 100vh;
    }
  }
}

.home-intro {
  z-index: 2;
}

.home-intro__title {
  @include heading(2rem);

  @include media(xsmall-only) {
    @include heading(1.5rem);
  }

  @include media(large-up) {
    @include heading(3.5rem);
  }

  span {
    display: block;
    font-size: 1rem;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: $global-spacing * 0.25;

    @include media(large-up) {
      font-size: 1.25rem;
      margin-bottom: $global-spacing * 0.5;
    }
  }
}

.home-intro__lead {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: $global-spacing * 2;

  @include media(xsmall-only) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  @include media(large-up) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.stats-list {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  font-weight: $base-font-medium;
  margin-bottom: $global-spacing * 2.5;

  * {
    font-weight: inherit;
    line-height: 1;
    margin: 0;
  }

  dt {
    color: inherit;
    font-size: 1rem;
    margin-right: $global-spacing * 2;
    padding: 0 0 ($global-spacing * 0.125) 0;

    @include media(large-up) {
      font-size: 1.25rem;
      padding: 0 0 ($global-spacing * 0.5) 0;
    }

    &:nth-of-type(1) {
      order: 2;
    }

    &:nth-of-type(2) {
      order: 4;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  dd {
    color: $secondary-color;
    font-size: 2rem;
    line-height: 1;
    margin-right: $global-spacing * 0.5;

    @include media(large-up) {
      font-size: 4rem;
      margin-right: $global-spacing;
    }

    &:nth-of-type(1) {
      order: 1;
    }

    &:nth-of-type(2) {
      order: 3;
    }
  }
}

.cta-wrapper {
  small {
    @extend .visually-hidden;
  }

  @include media(xsmall-only) {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
  }
}

.ctab {
  @extend .button;
  margin-right: $global-spacing * 0.5;
  flex: 1;
  min-width: 10rem;
  margin: 0.5rem;

  @include media(small-up) {
    flex: 0;
    min-width: 12rem;
    margin: 1rem;
  }

  @include media(large-up) {
    min-width: 12rem;
    line-height: 2rem;
    font-size: 0.875rem;
    padding: 0.5rem 2rem;
    margin-right: $global-spacing;
  }
}

.ctab--shifting {
  @extend .button--primary-raised-dark;

  &:before {
    @extend %collecticon-wave-shifting;
  }
}

.ctab--smoothing {
  @extend .button--primary-raised-dark;

  &:before {
    @extend %collecticon-wave-smoothing;
  }
}

.ctab--about {
  @extend .button--primary-raised-light;
}

.home-intro__attribution {
  display: flex;
  list-style: none;
  margin: 6rem 0 0 0;
  padding: 0;
  flex-direction: column;

  @include media(xsmall-only) {
    margin: 3rem 0 0 0;
  }

  li {
    margin-right: 2rem;
    margin-bottom: 0.25rem;
  }

  img {
    height: 2rem;

    @include media(large-up) {
      height: 2.5rem;
    }
  }
}

/* Illustration
   ========================================================================== */

.home-media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  overflow: hidden;

  svg {
    display: block;
    max-width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    position: absolute;
  }

  figcaption {
    @extend .visually-hidden;
  }
}

.home-media__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Animation */
  animation: illu-in 1.5s ease-in-out 0s 1;
}

@keyframes illu-in {
  from {
    opacity: 0;
    transform: translate(4%, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

#esvj8pgc0gbj166_to {
  animation: esvj8pgc0gbj166_to__to 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj166_to__to {
  0% {
    transform: translate(-352.00747px, 0px);
  }
  94.700000% {
    transform: translate(-722.089296px, -1.739671px);
  }
  100% {
    transform: translate(-722.089296px, -1.739671px);
  }
}
#esvj8pgc0gbj166 {
  animation: esvj8pgc0gbj166_c_o 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj166_c_o {
  0% {
    opacity: 0;
  }
  13% {
    opacity: 0;
  }
  18% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  88% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#esvj8pgc0gbj167_to {
  animation: esvj8pgc0gbj167_to__to 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj167_to__to {
  0% {
    transform: translate(2438.744704px, 21.446442px);
  }
  6% {
    transform: translate(2438.744704px, 21.446442px);
  }
  99% {
    transform: translate(2238.053704px, 21.139165px);
  }
  100% {
    transform: translate(2238.053704px, 21.139165px);
  }
}
#esvj8pgc0gbj167 {
  animation: esvj8pgc0gbj167_c_o 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj167_c_o {
  0% {
    opacity: 0;
  }
  6% {
    opacity: 0;
  }
  14% {
    opacity: 1;
  }
  88% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#esvj8pgc0gbj171_to {
  animation: esvj8pgc0gbj171_to__to 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj171_to__to {
  0% {
    transform: translate(-475.524296px, -1.311708px);
  }
  40.100000% {
    transform: translate(-223.637942px, -9.658192px);
  }
  45% {
    transform: translate(-223.637942px, -9.658192px);
  }
  81% {
    transform: translate(-223.637868px, -3.367008px);
  }
  100% {
    transform: translate(-30.564796px, -2.826237px);
  }
}
#esvj8pgc0gbj171 {
  animation: esvj8pgc0gbj171_c_o 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj171_c_o {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  91% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
#esvj8pgc0gbj172_to {
  animation: esvj8pgc0gbj172_to__to 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj172_to__to {
  0% {
    transform: translate(-629.484296px, 33.419px);
  }
  2% {
    transform: translate(-426.271477px, 23.904px);
  }
  40% {
    transform: translate(-724.719296px, 23.904px);
  }
  85% {
    transform: translate(-719.325295px, 23.904px);
  }
  100% {
    transform: translate(-813.102296px, 23.904px);
  }
}
#esvj8pgc0gbj172 {
  animation: esvj8pgc0gbj172_c_o 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj172_c_o {
  0% {
    opacity: 0;
  }
  6% {
    opacity: 0;
  }
  13% {
    opacity: 1;
  }
  93% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#esvj8pgc0gbj174 {
  animation: esvj8pgc0gbj174_s_do 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj174_s_do {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
#esvj8pgc0gbj175 {
  animation: esvj8pgc0gbj175_s_do 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj175_s_do {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
#esvj8pgc0gbj176 {
  animation: esvj8pgc0gbj176_s_do 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj176_s_do {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
#esvj8pgc0gbj177 {
  animation: esvj8pgc0gbj177_s_do 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj177_s_do {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
#esvj8pgc0gbj178 {
  animation: esvj8pgc0gbj178_s_do 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj178_s_do {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
#esvj8pgc0gbj179 {
  animation: esvj8pgc0gbj179_s_do 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj179_s_do {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
#esvj8pgc0gbj180 {
  animation: esvj8pgc0gbj180_s_do 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj180_s_do {
  0% {
    stroke-dashoffset: 0;
  }
  40.500000% {
    stroke-dashoffset: 200;
  }
  100% {
    stroke-dashoffset: 200;
  }
}
#esvj8pgc0gbj181 {
  animation: esvj8pgc0gbj181_s_do 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj181_s_do {
  0% {
    stroke-dashoffset: 0;
  }
  40.500000% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 300;
  }
}
#esvj8pgc0gbj182 {
  animation: esvj8pgc0gbj182_s_do 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj182_s_do {
  0% {
    stroke-dashoffset: 0;
  }
  40.500000% {
    stroke-dashoffset: 200;
  }
  100% {
    stroke-dashoffset: 200;
  }
}
#esvj8pgc0gbj245 {
  animation: esvj8pgc0gbj245_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj245_f_p {
  0% {
    fill: rgb(255, 255, 255);
  }
  18.500000% {
    fill: rgb(255, 255, 255);
  }
  19.500000% {
    fill: rgb(243, 194, 54);
  }
  29% {
    fill: rgb(243, 194, 54);
  }
  37% {
    fill: rgb(243, 194, 54);
  }
  37.500000% {
    fill: rgb(255, 255, 255);
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
#esvj8pgc0gbj263 {
  animation: esvj8pgc0gbj263_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj263_f_p {
  0% {
    fill: rgb(255, 255, 255);
  }
  75.450000% {
    fill: rgb(255, 255, 255);
  }
  76% {
    fill: rgb(243, 194, 54);
  }
  90% {
    fill: rgb(243, 194, 54);
  }
  91% {
    fill: rgb(255, 255, 255);
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
#esvj8pgc0gbj266 {
  animation: esvj8pgc0gbj266_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj266_f_p {
  0% {
    fill: rgb(255, 255, 255);
  }
  44% {
    fill: rgb(255, 255, 255);
  }
  44.500000% {
    fill: rgb(243, 194, 54);
  }
  97% {
    fill: rgb(243, 194, 54);
  }
  97.500000% {
    fill: rgb(255, 255, 255);
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
#esvj8pgc0gbj269 {
  animation: esvj8pgc0gbj269_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj269_f_p {
  0% {
    fill: rgb(255, 255, 255);
  }
  11% {
    fill: rgb(255, 255, 255);
  }
  11.500000% {
    fill: rgb(243, 194, 54);
  }
  82% {
    fill: rgb(243, 194, 54);
  }
  82.500000% {
    fill: rgb(255, 255, 255);
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
#esvj8pgc0gbj286 {
  animation: esvj8pgc0gbj286_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj286_f_p {
  0% {
    fill: rgb(255, 255, 255);
  }
  23.500000% {
    fill: rgb(255, 255, 255);
  }
  24% {
    fill: rgb(243, 194, 54);
  }
  49% {
    fill: rgb(243, 194, 54);
  }
  49.500000% {
    fill: rgb(255, 255, 255);
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
#esvj8pgc0gbj300 {
  animation: esvj8pgc0gbj300_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj300_f_p {
  0% {
    fill: rgb(255, 255, 255);
  }
  31.500000% {
    fill: rgb(255, 255, 255);
  }
  32% {
    fill: rgb(243, 194, 54);
  }
  54% {
    fill: rgb(243, 194, 54);
  }
  54.500000% {
    fill: rgb(255, 255, 255);
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
#esvj8pgc0gbj319 {
  animation: esvj8pgc0gbj319_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj319_f_p {
  0% {
    fill: rgb(255, 255, 255);
  }
  4.500000% {
    fill: rgb(255, 255, 255);
  }
  5% {
    fill: rgb(243, 194, 54);
  }
  64% {
    fill: rgb(243, 194, 54);
  }
  64.500000% {
    fill: rgb(255, 255, 255);
  }
  100% {
    fill: rgb(255, 255, 255);
  }
}
#esvj8pgc0gbj397 {
  animation: esvj8pgc0gbj397_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj397_f_p {
  0% {
    fill: rgb(10, 160, 219);
  }
  32% {
    fill: rgb(10, 160, 219);
  }
  32.500000% {
    fill: rgb(244, 194, 55);
  }
  48.500000% {
    fill: rgb(244, 194, 55);
  }
  49% {
    fill: rgb(10, 160, 219);
  }
  100% {
    fill: rgb(10, 160, 219);
  }
}
#esvj8pgc0gbj418 {
  animation: esvj8pgc0gbj418_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj418_f_p {
  0% {
    fill: rgb(10, 160, 219);
  }
  15.500000% {
    fill: rgb(10, 160, 219);
  }
  16% {
    fill: rgb(244, 194, 55);
  }
  68% {
    fill: rgb(244, 194, 55);
  }
  68.500000% {
    fill: rgb(10, 160, 219);
  }
  100% {
    fill: rgb(10, 160, 219);
  }
}
#esvj8pgc0gbj421 {
  animation: esvj8pgc0gbj421_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj421_f_p {
  0% {
    fill: rgb(10, 160, 219);
  }
  11% {
    fill: rgb(10, 160, 219);
  }
  11.500000% {
    fill: rgb(244, 194, 55);
  }
  74% {
    fill: rgb(244, 194, 55);
  }
  74.500000% {
    fill: rgb(10, 160, 219);
  }
  100% {
    fill: rgb(10, 160, 219);
  }
}
#esvj8pgc0gbj424 {
  animation: esvj8pgc0gbj424_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj424_f_p {
  0% {
    fill: rgb(10, 160, 219);
  }
  6.500000% {
    fill: rgb(10, 160, 219);
  }
  7% {
    fill: rgb(244, 194, 55);
  }
  79% {
    fill: rgb(244, 194, 55);
  }
  79.500000% {
    fill: rgb(10, 160, 219);
  }
  100% {
    fill: rgb(10, 160, 219);
  }
}
#esvj8pgc0gbj446 {
  animation: esvj8pgc0gbj446_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj446_f_p {
  0% {
    fill: rgb(244, 194, 55);
  }
  84% {
    fill: rgb(244, 194, 55);
  }
  84.500000% {
    fill: rgb(10, 160, 219);
  }
  100% {
    fill: rgb(10, 160, 219);
  }
}
#esvj8pgc0gbj450 {
  animation: esvj8pgc0gbj450_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj450_f_p {
  0% {
    fill: rgb(10, 160, 219);
  }
  28% {
    fill: rgb(10, 160, 219);
  }
  28.500000% {
    fill: rgb(244, 194, 55);
  }
  53.500000% {
    fill: rgb(244, 194, 55);
  }
  54% {
    fill: rgb(10, 160, 219);
  }
  100% {
    fill: rgb(10, 160, 219);
  }
}
#esvj8pgc0gbj454 {
  animation: esvj8pgc0gbj454_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj454_f_p {
  0% {
    fill: rgb(10, 160, 219);
  }
  23.500000% {
    fill: rgb(10, 160, 219);
  }
  24% {
    fill: rgb(244, 194, 55);
  }
  58% {
    fill: rgb(244, 194, 55);
  }
  58.500000% {
    fill: rgb(10, 160, 219);
  }
  100% {
    fill: rgb(10, 160, 219);
  }
}
#esvj8pgc0gbj458 {
  animation: esvj8pgc0gbj458_f_p 20000ms linear infinite normal backwards;
}
@keyframes esvj8pgc0gbj458_f_p {
  0% {
    fill: rgb(10, 160, 219);
  }
  18.500000% {
    fill: rgb(10, 160, 219);
  }
  19% {
    fill: rgb(244, 194, 55);
  }
  63% {
    fill: rgb(244, 194, 55);
  }
  63.500000% {
    fill: rgb(10, 160, 219);
  }
  100% {
    fill: rgb(10, 160, 219);
  }
}

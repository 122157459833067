/* ==========================================================================
   Global: lists
   ========================================================================== */

/* Card list
   ========================================================================== */

.card-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: $global-spacing;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  grid-auto-rows: auto;

  @include media(small-up) {
    grid-gap: ($global-spacing * 2);
  }
}

/* Legend list
   ========================================================================== */

.legend-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 ($global-spacing * 0.5);
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;

  dt,
  dd {
    margin: 0;
  }

  dt {
    grid-column: 1 / span 1;
    margin: 0;
    display: flex;

    .lgfx {
      margin: 0.375rem 0;
    }
  }

  dd {
    grid-column: 2 / span 11;
  }
}

.lgfx-wrapper {
  display: flex;
}

.lgfx {
  content: "";
  min-width: $global-spacing * 1.5;
  height: $global-spacing * 0.5;
  border-radius: $full-border-radius;
  background: $base-color;
  font-size: 0;
}

.chart-note {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba($base-color, 0.64);
}

/* Logo list
   ========================================================================== */

.logo-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 $global-spacing;
  list-style: none;
  padding: 0;
  margin: 0;

  dt {
    grid-column: 1 / span 12;

    &:not(:first-child) {
      margin-top: $global-spacing;
    }
  }

  dd {
    grid-column: auto / span 6;

    @include media(small-up) {
      grid-column: auto / span 4;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    height: 6rem;
    position: relative;
    z-index: 1;
    border-radius: $base-border-radius;
    box-shadow: inset 0 0 0 1px $base-alpha-color;
    transition: all 0.16s ease 0s;
  }

  span {
    @extend .visually-hidden;
  }

  img {
    display: inline-flex;
    width: auto;
    max-width: 100%;
    max-height: 3rem;
  }

  /* Compensate DevSeed logo proportions and alignment */
  .logo-devseed img {
    max-height: 2rem;
  }

  /* Compensate ESMAP logo proportions and alignment */
  .logo-esmap img {
    max-height: 2.25rem;
  }
}

/* ==========================================================================
   Explore: single
   ========================================================================== */

.inpage--explore.inpage--single {
  .inpage__header {
    overflow: hidden; /* Fixed for ShadowScrollbar */
  }

  .inpage__subheader {
    box-shadow: none;
  }

  .inpage__title {
    line-height: 1.5rem;
  }

  .inpage__title,
  .inpage__subtitle {
    @extend .truncated;
    max-width: 100%;
  }

  .inpage__body {
    display: grid;
    padding: 0;
    @include media(medium) {
      min-height: calc(100vh - 56px);
      max-height: calc(100vh - 56px);
    }
    @include media(large-up) {
      min-height: 100vh;
      max-height: 100vh;
    }

    .inpage__results {
      margin: 0 0.25rem;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      justify-content: space-between;
      @include media(medium) {
        min-height: calc(100vh - 56px);
        max-height: calc(100vh - 56px);
      }
      @include media(large-up) {
        min-height: 100vh;
        max-height: 100vh;
        margin-left: 1rem;
      }
    }

    .inpage__results_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.25rem;
    }

    .inpage__results_chart {
      margin-top: 1rem;
      flex: auto;
    }

    .inpage__textresults {
      flex: initial;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      box-shadow: 0 (2 * $base-border-width) 0 0 $base-alpha-color;

      @include media(medium-up) {
        padding: 0 2rem;
        flex-wrap: nowrap;
      }

      .textresults__container {
        margin: 0.25rem;
        flex-basis: 45%;
        @include media(medium-up) {
          flex-basis: 25%;
        }
      }

      .textresults__title {
        color: grey;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
      }
      .textresults__value {
        color: $primary-color;
      }
      .textresults__value_number {
        font-size: 1.75rem;
      }
      .textresults__value_units {
        font-size: 0.85rem;
      }
      .textresults__constrained {
        &:after {
          color: $success-color;
          vertical-align: super;
          font-size: 70%;
          content: "*";
          // don't mess up the line height
          line-height: 0;
        }
      }
    }

    .inpage__textresults_v {
      flex: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      flex-basis: 50%;
      flex-grow: 0;
      @include media(large-up) {
        flex-basis: 240px;
      }
    }

    .inpage__results_pie {
      min-width: 100px;
      min-height: 100px;
      max-width: 200px;
      flex-basis: 50%;
      @include media(medium-up) {
        flex-basis: 133px;
      }
    }

    figure.results__cost_pie {
      margin: 0;
    }
  }
}

.primary-color {
  color: $primary-color !important;
}
.darker-primary-color {
  color: $darker-primary-color !important;
}
.danger-color {
  color: $danger-color !important;
}
.base-color {
  color: $base-color !important;
}

/* Controls
   ========================================================================== */

.econtrols {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden; /* Fixed for ShadowScrollbar */
  height: 100%;

  .nav {
    margin: 0;
    position: relative;
    z-index: 80;
  }

  .nav__tablist {
    padding: 0 $contained-spacing;
  }
}

.econtrols__section {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden; /* Fixed for ShadowScrollbar */

  /* Animation */
  animation: reveal 0.32s ease 0s 1;
}

.econtrols__title {
  @extend .visually-hidden;
}

.econtrols__block {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  overflow: hidden; /* Fixed for ShadowScrollbar */
}

.econtrols__subblock {
  position: relative;
  z-index: 60;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  overflow: hidden; /* Fixed for ShadowScrollbar */
  height: 300px;
}

.econtrols__item {
  padding: $global-spacing $contained-spacing;
  //box-shadow: inset 0 (-$base-border-width) 0 0 $base-alpha-color;
  margin: 0;

  &:last-child {
    box-shadow: none;
  }

  .form__label,
  .form__option__text,
  .form__output {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .form__option__ui {
    margin: 0;
  }

  .form__option--switch {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin: 0;

    .form__option__text {
      order: -1;
      font-weight: $base-font-bold;
      padding-right: $global-spacing;
      margin: 0;
    }

    .form__option__ui {
      margin: 0.25rem 0 0.25rem auto;
    }
  }

  .input-range__label--value {
    @extend .visually-hidden;
  }

  .form__inner-actions {
    margin-top: -0.125rem;

    > *:last-child {
      margin-right: -0.375rem;
    }
  }

  .form__slider-group {
    margin-top: -0.75rem;
  }
}

.econtrols__actions {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 80;
  padding: $contained-spacing;
  max-width: 100vw;
}

.econtrols__submit {
  @extend .button, .button--primary-raised-dark;

  &::before {
    @extend %collecticon-sm-tick;
  }
}

.econtrols__download {
  @extend .button, .button--primary-raised-dark;

  &::before {
    @extend %collecticon-download-2;
  }
}

.econtrols__upload {
  @extend .button, .button--primary-raised-dark;

  &::before {
    @extend %collecticon-upload-2;
  }
}

.econtrols__map {
  @extend .button, .button--primary-raised-dark;

  &::before {
    @extend %collecticon-marker;
  }
}

.econtrols__time-select {
  @extend .button, .button--primary-raised-light;
  min-width: 5rem;

  &::after {
    @extend %collecticon-sm-chevron-down;
    margin-right: -0.5rem;
  }
}

.econtrols__reset {
  @extend .button, .button--primary-raised-light;

  &::before {
    @extend %collecticon-arrow-loop;
  }
}

/* 'eci' stands for econtrol item */
.eci-info {
  @extend .button, .button--small, .button--base-plain, .button--text-hidden;

  &::before {
    @extend %collecticon-circle-information;
    opacity: 0.56;
  }
}

/* Map
   ========================================================================== */

.exp-map {
  position: relative;
  z-index: 10;
  background: tint($base-color, 96%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Fixed for ShadowScrollbar */

  /* Animation */
  animation: reveal 0.64s ease 0s 1;

  .mapboxgl-ctrl-group {
    margin-bottom: 0;
  }
}

.exp-map__title {
  @extend .visually-hidden;
}

.map-number-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  > * {
    grid-column: auto / span 12;
    margin: 0;
  }

  dt {
    font-size: 0.75rem;
    margin-bottom: $global-spacing;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  dd {
    font-size: 1rem;
    font-weight: $base-font-bold;

    @for $child from 1 through 6 {
      &:nth-of-type(#{$child}) {
        grid-row: 2 * $child - 1;
      }
    }
  }

  small {
    font-size: inherit;
  }
}

.mapboxgl-ctrl > button {
  @extend .button, .button--primary-raised-light, .button--text-hidden;
}

.layers-menu-trigger::before {
  @extend %collecticon-iso-stack;
}

.layers-menu {
  width: 20rem;
  height: 15.5rem;
  padding: 0;

  .drop__title {
    margin-bottom: $global-spacing * 1.5;
  }

  .rcs {
    > div > div {
      padding: $global-spacing;
    }
  }
}

.layers-list {
  list-style: none;
  margin: 0 (-$global-spacing);
  padding: 0;

  &__item {
    padding: $global-spacing;
    box-shadow: 0 $base-border-width 0 0 $base-alpha-color;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      box-shadow: none;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .form__group {
    margin-bottom: $global-spacing * 0.5;
  }

  .form__option--switch {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin: 0;

    .form__option__text {
      order: -1;
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding-right: $global-spacing;
      margin: 0;
    }

    .form__option__ui {
      margin: 0.25rem 0 0.25rem auto;
    }
  }

  .form__help {
    position: relative;
    padding-top: $global-spacing * 0.5;
    text-align: left;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 2rem;
      height: 1px;
      content: "";
      background: $base-alpha-color;
    }
  }
}

/* Summary
   ========================================================================== */

.exp-summary {
  @extend %pane-skin;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;

  /* Animation */
  animation: reveal 0.96s ease 0s 1;
}

.exp-summary__header {
  position: relative;
  z-index: 80;
  display: flex;
  flex-flow: row nowrap;
  padding: $global-spacing $contained-spacing;
  box-shadow: 0 $base-border-width 0 0 $base-alpha-color;

  > *:last-child {
    margin-bottom: 0;
  }
}

.exp-summary__headline {
  > *:last-child {
    margin-bottom: 0;
  }
}

.exp-summary__title {
  @include heading(1rem);
  margin-bottom: 0;
}

.exp-summary__subtitle {
  @extend .heading-alt;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba($base-font-color, 0.64);
  margin: 0 0 $global-spacing 0;
}

.exp-summary__disclaimer {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba($base-font-color, 0.64);
  margin: 0;

  > *:last-child {
    margin-bottom: 0;
  }
}

.exp-summary__body {
  position: relative;
  z-index: 60;
  flex: 1;
  overflow: hidden; /* Fixed for ShadowScrollbar */
}

.exp-summary__footer {
  position: relative;
  z-index: 80;
  padding: $contained-spacing;
  box-shadow: 0 (-$base-border-width) 0 0 $base-alpha-color;
  text-align: center;
}

.exp-download-button {
  @extend .button, .button--primary-raised-dark;
  min-width: 10rem;
  vertical-align: top;

  &::before {
    @extend %collecticon-download-2;
  }
}

.sum-block {
  padding: $global-spacing $contained-spacing;
  box-shadow: 0 $base-border-width 0 0 $base-alpha-color;

  &:last-child {
    box-shadow: none;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.sum-block--message {
  height: 100%;
  text-align: center;
  color: rgba($base-font-color, 0.64);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.sum-block--error h2 {
  color: $danger-color;
}

.sum-block--charts {
  padding: ($global-spacing * 2) $contained-spacing;
}

.sum-block__title {
  @extend .visually-hidden;
}

.sum-number-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  > * {
    grid-column: auto / span 12;
    margin: 0;
  }

  dt {
    font-size: 0.75rem;
    margin-bottom: $global-spacing;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  dd {
    font-size: 1.25rem;
    font-weight: $base-font-bold;

    @for $child from 1 through 6 {
      &:nth-of-type(#{$child}) {
        grid-row: 2 * $child - 1;
      }
    }
  }

  small {
    font-size: inherit;
  }
}

.sum-chart-media,
.profile-chart-media {
  text-align: center;
  margin-bottom: 0;

  /* Animation */
  /*animation: pop-in 0.16s ease 0s 1;*/
}

.sum-area-chart-media__item,
.profile-chart-media__item {
  margin-bottom: 0;

  text {
    fill: $base-color;
    opacity: 0.8;
  }

  svg {
    vertical-align: top;
    cursor: pointer;

    /* Animation */
    transition: transform 0.16s ease 0s;

    .vx-axis-left {
      stroke-opacity: 0.5;
      text {
        font-size: 0.7em;
      }
    }

    .vx-axis-bottom {
      stroke-opacity: 0.5;
      text {
        font-size: 0.9em;
      }
    }
  }

  .chart__yaxis_label {
    font-size: 0.8rem;
    font-weight: $heading-font-bold;
  }

  .chart__legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    font-size: 0.8rem;
    padding-right: 35px; /* match left margin in svg */
    @include media(large-up) {
      flex-wrap: nowrap;
    }
  }
  .chart__legend_item {
    padding-left: 1.5rem;
  }
  .chart__legend_label {
    padding-left: 0.25rem;
  }
}

.value--sub {
  font-size: 0.75rem;
}

.sum-chart-media__caption {
  @extend .heading-alt;
  color: rgba($base-font-color, 0.64);
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: -1.3rem;
}

.profile-chart-media__caption {
  @extend .heading-alt;
  color: rgba($base-font-color, 0.64);
  font-size: 0.75rem;
  line-height: 1rem;
}

.chart-number-list {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0 ($global-spacing * 0.5);

  > * {
    margin: 0;
  }

  dt {
    display: flex;
    align-items: center;
    grid-column: 1;
  }

  dd {
    display: flex;
    flex-flow: row nowrap;
    font-size: 1rem;
    font-weight: $base-font-bold;
    grid-column: 2;

    span {
      margin-right: 1rem;
      text-align: right;
      min-width: 4rem;
    }

    small {
      font-size: 0.75rem;
      font-weight: $base-font-regular;
      margin-left: auto;
    }
  }
}

.drop__menu-item--pdf::before {
  @extend %collecticon-page-label;
}

.drop__menu-item--shapefile::before {
  @extend %collecticon-expand-top-right;
}

.drop__menu-item--data::before {
  @extend %collecticon-expand-top-right;
}

/*
 * Solar Profile
 */

.solarprofile__container {
  width: 100%;
  height: 100%;
  position: relative;

  .solarprofile__mapcontainer {
    height: 300px;
    width: 100%;

    .exp-map {
      width: 100%;
      height: 100%;
    }
  }

  .solarprofile__profilecontainer {
    height: 150px;
    width: 100%;
  }

  .solarprofile__buttons {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
  }

  .dropzone__container {
    margin-top: 0.75rem;
    border: 2px dashed #aaa;
    position: relative;
  }
}

.input__error {
  border-color: #ff070c !important;
}

.dropzone__error_msg {
  color: #ff070c;
  text-align: right;
  font-size: 0.8rem;
  margin-left: auto;
}

.econtrol__cancel {
  @extend .button, .button--small, .button--base-plain, .button--text-hidden;
  position: absolute;
  right: 2px;
  top: 2px;
  &::before {
    @extend %collecticon-sm-xmark;
    margin-right: 0;
  }
}

.download-file-csv::before {
  @extend %collecticon-file-csv;
}

.download-file-pdf::before {
  @extend %collecticon-file-pdf;
}

.download-file-json::before {
  @extend %collecticon-file-code;
}

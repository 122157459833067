/* ==========================================================================
   Global: page
   ========================================================================== */

.page {
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr;

  @include media(large-up) {
    display: grid;
    grid-template-columns: ($global-spacing * 4) auto 0;
  }
}

/* Header
   ========================================================================== */

.page__header {
  @extend .antialiased;
  background: $primary-color;
  padding: 0.75rem $global-spacing;
  color: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @include media(large-up) {
    position: sticky;
    top: 0;
    left: 0;
    bottom: 0;
    flex-flow: column nowrap;
    height: 100vh;
    padding: $global-spacing;
  }
}

.page__headline {
  @include media(large-up) {
    display: none;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.page__hamburger {
  display: table-caption;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;

  @include media(large-up) {
    width: 2.5rem;
    font-size: 1.25rem;
    padding-top: $global-spacing * 0.25;
  }

  a {
    display: block;
    color: inherit;

    &::before {
      @extend %collecticon-hamburger-menu;
    }
  }

  span {
    @extend .visually-hidden;
  }
}

.page__prime-nav {
  display: flex;
  margin: 0 0 0 auto;

  @include media(large-up) {
    flex-flow: column nowrap;
    flex: 1;
    margin: 0;
  }
}

/* Global menu */

.global-menu {
  flex: 1;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @include media(large-up) {
    flex-flow: column nowrap;
  }

  > * {
    margin: 0 0 0 ($global-spacing * 0.25);

    @include media(large-up) {
      margin: 0 0 ($global-spacing * 0.5) 0;
    }

    &:last-child {
      @include media(large-up) {
        margin: 0 0 0 0;
      }
    }
  }
}

.global-menu__link {
  @extend .button, .button--achromic-plain, .button--text-hidden;

  &:last-child {
    @include media(large-up) {
      line-height: 1.5rem;
      padding: 0.5rem 1.5rem;
      min-width: 2.5rem;
    }
  }

  &--active {
    @extend .button--active;
  }
}

.global-menu__link--default::before {
  @extend %collecticon-square;
}

.global-menu__link--home::before {
  @extend %collecticon-house;
}

.global-menu__link--explore::before {
  @extend %collecticon-compass;
}

.global-menu__link--docs::before {
  @extend %collecticon-book--bookmark;
}

.global-menu__link--about::before {
  @extend %collecticon-circle-information;
}

.global-menu__link--share::before {
  @extend %collecticon-share;
}

.global-menu__link--smoothing::before {
  @extend %collecticon-wave-smoothing;
}

.global-menu__link--shifting::before {
  @extend %collecticon-wave-shifting;
}

/* Body
   ========================================================================== */

.page__body {
}

/* Footer
   ========================================================================== */

.page__footer {
}

.inpage--about {
  @include media(large-up) {
    grid-template-columns: 15rem auto;
    .inpage__header {
      grid-column-end: span 2;
    }
  }
}

.about-menu {
  margin: 1rem;

  .about-menu__link--active {
    font-weight: 500;
  }

  li {
    list-style-type: none;
    padding: 1rem;
  }
}

/* ==========================================================================
   Main
   ========================================================================== */

/**
 * Following Necolas' Principles of writing consistent, idiomatic CSS:
 * https://github.com/necolas/idiomatic-css
 */

/* Charset */

@charset 'UTF-8';

/* Third-party
   ========================================================================== */

/**
 * Using Bourbon for extended SASS functionality:
 * http://bourbon.io/
 */

@import "bourbon/core/bourbon";

/**
 * Using Jeet, a grid system designed for humans:
 * http://jeet.gs/
 */

@import "jeet/jeet.scss";

/**
 * Normalize.css, a modern, HTML5-ready alternative to CSS resets:
 * http://necolas.github.io/normalize.css/
 */

@import "vendor/normalize";

/**
 * Kenneth Ormandy's OpenType features—ligatures, kerning, and more—to Normalize.css:
 * https://github.com/kennethormandy/normalize-opentype.css
 */

@import "vendor/normalize-opentype";

/* Settings
   ========================================================================== */

@import "settings/variables";
@import "settings/functions";
@import "settings/mixins";

/* Core
   ========================================================================== */

@import "core/base";
/* there's a path issue in collecticons when in core */
@import "collecticons"; // Webfont icon library: Collecticons # generated
@import "core/typography";
@import "core/tables";
@import "core/media";
@import "core/skins";
@import "core/animation";

/* Global
   ========================================================================== */

@import "global/buttons";
@import "global/toolbar";
@import "global/forms";
@import "global/drops";
@import "global/popovers";
@import "global/infographics";
@import "global/labels";
@import "global/lists";
@import "global/cards";
@import "global/navs";
@import "global/loading";
@import "global/page";
@import "global/inpage";
@import "global/share";
@import "global/messages";

/* Home
   ========================================================================== */

@import "home/global";

/* Explore
   ========================================================================== */

@import "explore/global";
@import "explore/hub";
@import "explore/single";

/* Documentation
   ========================================================================== */

@import "documentation/global";
@import "about/inpage";

/* Static
   ========================================================================== */

@import "static/global";

/* Playground
   ========================================================================== */

@import "playground/global";

/* Core (...cont)
   ========================================================================== */

@import "core/utilities";

/* Vendor
   ========================================================================== */

@import "vendor/react-tooltip";
@import "vendor/react-paginate";
@import "vendor/react-custom-scrollbars";
@import "vendor/react-input-range/index";
@import "vendor/mapbox-gl";
@import "vendor/mapbox-gl-geocoder";
@import "vendor/outdated-browser";
@import "vendor/Collapsible";

@import "global/winfix";
